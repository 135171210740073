import InputBasic from "../components/InputBasic"
import InputSelect from "../components/InputSelect"
import InputCalendar from "../components/InputCalendar";
import NewInputBasic from "../components/NewInputBasic"
import NewInputSelect from "../components/NewInputSelect";
import NewInputCalendar from "../components/NewInputCalendar";

export const filtersSiniestros = (initialData) => {

    return [
        {
          type: "number",
          name: "poliza",
          labelName: "Poliza",
          placeholder: "Poliza",
          component: NewInputBasic,
        },
        {
          type: "number",
          name: "dni_siniestro",
          labelName: "DNI",
          placeholder: "19556234",
          component: NewInputBasic,
        },
        {
          type: "text",
          name: "num_siniestro",
          labelName: "Nro. Siniestro",
          placeholder: "Nro. Siniestro",
          component: NewInputBasic,
        },
        {
          name: "compania",
          labelName: "Compañia",
          options: initialData.companias,
          placeholder: "Compañia",
          component: NewInputSelect,
        },
        {
          name: "tipo_siniestro",
          labelName: "Tipo",
          options: initialData?.tipos?.length && [...initialData.tipos, {
            value: '11',
            label: 'Otros',
            key: initialData.tipos.length + 1
          }],
          placeholder: "Tipo",
          component: NewInputSelect,
        },
        {
          name: "estado",
          labelName: "Estado",
          options: initialData.estados,
          placeholder: "Estado",
          component: NewInputSelect,
        },
        {
          name: "ramo",
          labelName: "Ramos",
          options: initialData.ramos,
          placeholder: "Ramo",
          component: NewInputSelect,
        },
        {
          name: "desde",
          type: "date",
          labelName: "Desde",
          component: NewInputCalendar,
        },
        {
          name: "hasta",
          type: "date",
          labelName: "Hasta",
          component: NewInputCalendar,
        },
    ];
}

export const filterSiniestrosInitialValues = {
  poliza: "",
  dni_siniestro: "",
  num_siniestro: "",
  compania: "",
  tipo_siniestro: "",
  estado: "",
  prioridad: "",
  ramo: "",
  desde:"",
  hasta: ""
}

// SOLICITUD EMISION
export const filtersSolicitudEmision = (initialData) => {

  const user = JSON.parse(localStorage.getItem('userInfo'))

  const filters = [
    {
      type: "text",
      name: "cliente",
      labelName: "Nombre",
      placeholder: "Nombre",
      component: NewInputBasic
    },
    {
      type: "number",
      name: "dni",
      labelName: "DNI",
      placeholder: "19556234",
      component: NewInputBasic
    },
    {
      type: "number",
      name: "telefono",
      labelName: "Teléfono",
      placeholder: "41256389",
      component: NewInputBasic 
    },
    {
      type: "text",
      name: "email",
      labelName: "Correo Electrónico",
      placeholder: "info@segurobici.com.ar",
      component: NewInputBasic 
    },
    {
      name: "vendedor",
      labelName: "Vendedor",
      options: initialData.vendedores,
      placeholder: "Vendedor",
      component: NewInputSelect
    },
    {
      name: "operador",
      labelName: "Operador",
      options: initialData.operadores,
      placeholder: "Operador",
      component: NewInputSelect 
    },
    {
      name: "estado",
      labelName: "Estado",
      options: initialData.estados,
      placeholder: "Estado",
      component: NewInputSelect 
    },
    {
      name: "tipo_solicitud",
      labelName: "Tipo Solicitud",
      options: initialData.tiposSolicitudes,
      placeholder: "Tipo Solicitud",
      component: NewInputSelect 
    },
    {
      name: "fecha_desde",
      labelName: "Fecha Desde",
      component: NewInputCalendar 
    },
    {
      name: "fecha_hasta",
      labelName: "Fecha Hasta",
      component: NewInputCalendar 
    }
  ]
  
  if(user.id_rol === 6){
    return filters.filter(fil => fil.name !== "operador")
  } else {
    return filters
  }
}

export const filterSolicitudEmisionInitialValues = {
  cliente: '',
  dni: '',
  telefono: '',
  email: '',
  vendedor: '',
  operador: '',
  estado: '',
  tipo_solicitud: '',
  fecha_desde: '',
  fecha_hasta: ''
}

// EMISIONES

// SOLICITUD EMISION
export const filtersEmisiones = (initialData) => {

  const user = JSON.parse(localStorage.getItem('userInfo'))

  const filters = [
    {
      type: "text",
      name: "cliente",
      labelName: "Nombre",
      placeholder: "Nombre",
      component: NewInputBasic
    },
    {
      type: "number",
      name: "dni",
      labelName: "DNI",
      placeholder: "19556234",
      component: NewInputBasic
    },
    {
      type: "number",
      name: "telefono",
      labelName: "Teléfono",
      placeholder: "41256389",
      component: NewInputBasic 
    },
    {
      type: "text",
      name: "email",
      labelName: "Correo Electrónico",
      placeholder: "info@segurobici.com.ar",
      component: NewInputBasic 
    },
    {
      name: "compania",
      labelName: "Compañias",
      options: initialData.companias,
      placeholder: "Compañia",
      component: NewInputSelect
    },
    {
      name: "vendedor",
      labelName: "Vendedor",
      options: initialData.vendedores,
      placeholder: "Vendedor",
      component: NewInputSelect
    },
    {
      name: "operador",
      labelName: "Operador",
      options: initialData.operadores,
      placeholder: "Operador",
      component: NewInputSelect 
    },
    {
      name: "tipo_solicitud",
      labelName: "Tipo Solicitud",
      options: initialData.tiposSolicitudes,
      placeholder: "Tipo Solicitud",
      component: NewInputSelect 
    },
    {
      name: "fecha_desde",
      labelName: "Fecha Desde",
      component: NewInputCalendar 
    },
    {
      name: "fecha_hasta",
      labelName: "Fecha Hasta",
      component: NewInputCalendar 
    }
  ]

  if(user.id_rol === 6){
    return filters.filter(fil => fil.name !== "operador")
  } else {
    return filters
  }
}

export const filterEmisionesInitialValues = {
  cliente: '',
  dni: '',
  telefono: '',
  email: '',
  vendedor: '',
  operador: '',
  tipo_solicitud: '',
  fecha_desde: '',
  fecha_hasta: ''
}

// HISTORIAL SOLICITUDES
export const filtersHistorialSolicitudes = (initialData) => {
  return [
    {
      type: "text",
      name: "cliente",
      labelName: "Nombre",
      placeholder: "Nombre",
      component: NewInputBasic
    },
    {
      type: "number",
      name: "dni",
      labelName: "DNI",
      placeholder: "19556234",
      component: NewInputBasic
    },
    {
      type: "number",
      name: "telefono",
      labelName: "Teléfono",
      placeholder: "41256389",
      component: NewInputBasic 
    },
    {
      type: "text",
      name: "email",
      labelName: "Correo Electrónico",
      placeholder: "info@segurobici.com.ar",
      component: NewInputBasic 
    },
    {
      name: "estado",
      labelName: "Estado",
      options: initialData.estados,
      placeholder: "Estados",
      component: NewInputSelect 
    },
    {
      name: "tipo_solicitud",
      labelName: "Tipo Solicitud",
      options: initialData.tiposSolicitudes,
      placeholder: "Tipo Solicitud",
      component: NewInputSelect 
    },
    {
      name: "fecha_desde",
      labelName: "Fecha Desde",
      component: NewInputCalendar 
    },
    {
      name: "fecha_hasta",
      labelName: "Fecha Hasta",
      component: NewInputCalendar 
    }
  ]
}

export const filterHistorialSolicitudesInitialValues = {
  cliente: '',
  dni: '',
  telefono: '',
  email: '',
  estado: '',
  tipo_solicitud: '',
  fecha_desde: '',
  fecha_hasta: ''
}

// ESTADISTICAS PRODUCTO CANAL
export const filtersProductoCanal = (initialData) => {
  return [
    {
      name: "desde",
      type: "date",
      labelName: "Desde",
      component: NewInputCalendar,
    },
    {
      name: "hasta",
      type: "date",
      labelName: "Hasta",
      component: NewInputCalendar,
    },
    {
      name: "producto",
      labelName: "Producto",
      options: initialData.productos,
      component: NewInputSelect 
    }
  ]
}

export const filterProductoCanalInitialValues = {
  desde: '',
  hasta: '',
  producto: '',
}

// USUARIOS
export const filtersUsuarios = (initialData) => {

  return [
    {
      type: "text",
      name: "usuario_usuario",
      labelName: "Usuario",
      placeholder: "usuario@seguroweb.com.ar",
      component: NewInputBasic
    },
    {
      name: "estado_usuario",
      labelName: "Estado",
      options: initialData.estados,
      placeholder: "Estado",
      component: NewInputSelect 
    },
    {
      name: "id_rol",
      labelName: "Roles",
      options: initialData.roles,
      placeholder: "Roles",
      component: NewInputSelect 
    }
  ]
}

export const filterUsuariosInitialValues = {
  usuario_usuario: '',
  estado_usuario: '',
  id_rol: '',
}

// AUTOGESTION
export const filtersAutogestion = () => {

  return [
    {
      name: "nro_op",
      labelName: "Número de Operación",
      placeholder: "159839",
      component: NewInputBasic,
    },
    {
      name: "dni",
      labelName: "DNI",
      placeholder: "40955588",
      component: NewInputBasic,
    },
    {
      name: "nombre",
      labelName: "Nombre",
      placeholder: "Nombre",
      component: NewInputBasic,
    },
  ]
}

export const filterAutogestionInitialValues = {
  nro_op: "",
  dni: "",
  nombre: "",
}

// OBJETIVOS LEADS
export const filtersObjetivosLeads = (data) => {

  return [
    {
      name: "anio",
      labelName: "Año",
      options: data.anios,
      component: InputSelect
    },
    {
      name: "mes",
      labelName: "Mes",
      options: data.meses,
      component: InputSelect 
    },
    {
        name: "producto",
        labelName: "Producto",
        options: data.productos,
        component: InputSelect 
    },
    {
        name: "canal",
        labelName: "Canal",
        options: data.canales,
        component: InputSelect 
    },
  ]
}

export const filterObjetivosLeadsInitialValues = {
  anio: '',
  mes: '',
  producto: '',
  canal: ''
}

export const filtersHistoriaPremios = (initialData) => {

  return [
    {
      name: "mes",
      labelName: "Mes",
      options: initialData.meses,
      placeholder: "Mes",
      component: NewInputSelect 
    },
    {
      name: "vendedor",
      labelName: "Vendedores",
      options: initialData.vendedores,
      placeholder: "Vendedores",
      component: NewInputSelect 
    },
    {
      name: "tipo",
      labelName: "Tipos",
      options: initialData.tipos,
      placeholder: "Tipos",
      component: NewInputSelect 
    }
  ]
}

export const filterHistorialPremiosInitialValues = {
  mes: '',
  vendedor: '',
  tipo: ''
}



// RECHAZOS
export const filtersRechazos = (initialData) => {
  const user = JSON.parse(localStorage.getItem('userInfo'))

  const filters = [
    {
      type: "text",
      name: "id",
      labelName: "N. Rechazo",
      placeholder: "1231",
      component: NewInputBasic
    },
    {
      type: "text",
      name: "dni",
      labelName: "DNI",
      placeholder: "33333333",
      component: NewInputBasic
    },
    {
      type: "text",
      name: "nombre",
      labelName: "Nombre",
      placeholder: "Nombre",
      component: NewInputBasic
    },
    {
      type: "number",
      name: "poliza",
      labelName: "Póliza",
      placeholder: "19556",
      component: NewInputBasic
    },
    {
      name: "compania",
      labelName: "Compania",
      options: initialData.companias,
      placeholder: "Compañia",
      component: NewInputSelect
    },
    {
      name: "estado",
      labelName: "Estado",
      options: initialData.estados,
      placeholder: "Estado",
      component: NewInputSelect 
    },
    {
      name: "ramo",
      labelName: "Ramo",
      options: initialData.secciones,
      placeholder: "Ramo",
      component: NewInputSelect 
    },
    {
      name: "operador",
      labelName: "Operador",
      options: initialData.operadores,
      placeholder: "Operador",
      component: NewInputSelect
    },
    {
      name: "fecha_desde_ingreso",
      labelName: "Fecha Desde (Ingreso)",
      component: NewInputCalendar 
    },
    {
      name: "fecha_hasta_ingreso",
      labelName: "Fecha Hasta (Ingreso)",
      component: NewInputCalendar 
    },
    {
      name: "fecha_desde_ultima_modificacion",
      labelName: "Fecha Desde (Últ. Modificación)",
      component: NewInputCalendar 
    },
    {
      name: "fecha_hasta_ultima_modificacion",
      labelName: "Fecha Hasta (Últ. Modificación)",
      component: NewInputCalendar 
    },
    {
      type: "number",
      name: "veces_notificado",
      labelName: "V. Notificado",
      placeholder: "1",
      component: NewInputBasic
    },
    {
      name: "medio_contacto",
      labelName: "Medio de Contacto",
      options: [
        {label:'Whatsapp',value:'whatsapp',key:1},
        {label:'Telefono',value:'telefono',key:2},
        {label:'Email',value:'email',key:3}
      ],
      placeholder: "Medio",
      component: NewInputSelect 
    },

  ]

  if(user.id_rol === 6){
    return filters.filter(fil => fil.name !== "operador")
  } else {
    return filters
  }
}

export const filterRechazosInitialValues = {
  nombre: '',
  poliza: '',
  compania: '',
  estado: '',
  id: '',
  dni: '',
  fecha_desde_ingreso: '',
  fecha_hasta_ingreso: '',
  fecha_desde_ultima_modificacion: '',
  fecha_hasta_ultima_modificacion: '',
  operador: '',
  veces_notificado: ''
}


