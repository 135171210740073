import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    p {
        color: ${({ theme }) => theme.subtitle};
        font-size: 14px;
    }

    a {
        white-space: nowrap;
    }

    div select {
        width: 165.83px;
    }

    .inputs-container {
        display: flex;
        gap: 20px;
    }
`;

export const Premio = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.border};
    height: 100%;
    border-radius: 8px;
    gap: 12px;

    .titulo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
        border-bottom: 1px solid ${({ theme }) => theme.border};

        h4 {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .titulo-data {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .tipos-container {
        display: flex;
        gap: 6px;
    }

    .tipo-premio-tag {
        background-color: ${({ theme }) => theme.tag_background};
        color: ${({ theme }) => theme.tag_text_color};
        border-radius: 100px;
        font-size: 12px;
        padding: 4px 12px;
        box-sizing: border-box;
    }

    p {
        color: ${({ theme }) => theme.subtitle};
        font-size: 14px;
        line-height: 1.65;
        padding: 10px 20px 30px 20px;
        box-sizing: border-box;
    }

    a {
        white-space: nowrap;
    }

    div select {
        width: 165.83px;
    }

    .inputs-container {
        display: flex;
        gap: 20px;
    }
`;