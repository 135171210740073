import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;

    .solicitudes-seleccionadas {
        background-color: ${leerColor(colores.blanco)};
        color: ${leerColor(colores.azulRebranding)};
        box-sizing: border-box;
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 100%;
        margin: 0;
        font-weight: 600;
    }

    .filtros-aplicados {
        background-color: ${leerColor(colores.azulRebranding)};
        color: ${leerColor(colores.blanco)};
        box-sizing: border-box;
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 100%;
        margin: 0;
        font-weight: 600;
    }

    p {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        margin: 0;
    }

    hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }

  .filtros-aplicados-container {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};
    flex-direction: column;

    div {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        flex-wrap: wrap;
    }
  }

  .filtro-aplicado {
    font-size: 14px;
    color: ${leerColor(colores.azulRebranding)};
    border-radius: 100px;
    border: 1px solid ${leerColor(colores.azulRebranding)};
    padding: 10px 20px;
    transition: 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;

    :hover {
        color: ${leerColor(colores.blanco)};
        background-color: ${leerColor(colores.azulRebranding)};
    }
  }

`;

// export const Container = styled.div`
//   width: 100%;
//   margin: 0 auto;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 60px;
//   //border-top: 1px solid #eaeaea;
//   padding: 120px 0;

//   p {
//     margin: 0;
//     color: ${({ theme }) => theme.subtitle};
//     font-size: 14px;
//   }

//   strong {
//     color: ${leerColor(colores.azulRebranding)}
//   }
// `

export const Icon = styled.span`
    font-size: 60px;
    display: flex;
    color: ${leerColor(colores.azulRebranding)}
`