import { useEffect, useState } from "react";
import Divider from "../../../components/Divider";
import { Col, Grid } from "../../../components/Grid";
import Layout from "../../../components/Layout";
import NewInner from "../../../components/NewInner";
import NewInputSelect from "../../../components/NewInputSelect";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import NewWrapper from "../../../components/NewWrapper";
import { anios, meses } from "../../../constants/data";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import {
  anulacionesComisionesVendedorTable,
  comisionesVendedorTable,
  operacionesComisionesVendedorTable,
} from "../../../utils/tableData";
import NewTable from "../../../components/NewTable";
import { useTable } from "../../../hooks/useTable";
import formatSelectData from "../../../utils/formatSelectData";
import NewButton from "../../../components/NewButton";
import CardSinResultados from "../../../components/CardSinResultados";
import { useLoading } from "../../../hooks/useLoading";
import SpinnerLoading from "../../../components/SpinnerLoading";
import Subtitle from "../../../components/Subtitle";
import { FiDollarSign, FiFile, FiFolder, FiPercent } from "react-icons/fi";
import { useTabs } from "../../../hooks/useTabs";
import NewTabs from "../../../components/NewTabs";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ModalTotalPorcentajesComisiones from "../../../components/Modals/Comisiones/ModalPorcentajes";
import useModal from "../../../hooks/useModal";

const Comisiones = () => {
  const location = useLocation()
  const ven = location.pathname.split("/").length > 2 ? location.pathname.split("/")[2] : null
  const [mes, setMes] = useState(new Date().getMonth());
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [vendedor, setVendedor] = useState(ven ? parseInt(ven) : "");
  const [vendedores, setVendedores] = useState([]);
  const [comisiones, setComisiones] = useState([]);
  const [operaciones, setOperaciones] = useState([]);
  const [anulaciones, setAnulaciones] = useState([]);
  const { order, handleTableOrder } = useTable();
  const { loading, setLoading } = useLoading();
  const { tabSelected, handleTab } = useTabs()

  useEffect(() => {
    const getData = async () => {
      try {
        const vendedores = await axiosRequest.get("/premios/vendedores");
        const vendedoresList = formatSelectData(
          vendedores.data,
          "numero",
          "nombre",
          "numero"
        );
        setVendedores([{ label: "Todos", value: "0", key: 0},...vendedoresList]);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const handleComisiones = async () => {
    setComisiones([])
    setOperaciones([])
    setAnulaciones([])
    setLoading(true);
    try {
      const comisiones = await axiosRequest.get(`/comisiones/${vendedor}/${mes}/${anio}`);
      const operaciones = await axiosRequest.get(`/comisiones_operaciones/${vendedor}/${mes}/${anio}`);
      const anulaciones = await axiosRequest.get(`/comisiones_anulaciones/${vendedor}/${mes}/${anio}`)
      console.log(comisiones.data)
      setComisiones(comisiones.data)
      setAnulaciones(anulaciones.data)
      setOperaciones(operaciones.data)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const tableData = comisionesVendedorTable();
  const operacionesTableData = operacionesComisionesVendedorTable();
  const anulacionesTableData = anulacionesComisionesVendedorTable();

  useEffect(() => {
    if(ven){
      handleComisiones()
    }
  }, [ven])

  const TABS = [
    {
      name: "Comisiones"
    },
    {
      name: "Operaciones"
    },
    {
      name: "Anulaciones"
    },
  ]

  const porcentajesModal = useModal()

  return (
    <>
      {porcentajesModal.isModalOpen ? <ModalTotalPorcentajesComisiones modal={porcentajesModal} mes={mes} anio={anio} refreshData={handleComisiones} /> : null}
      <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <NewTitlePageSection
                title="Comisiones"
                description={ven ? "Tus comisiones, operaciones y anulaciones" : "Lista de comisiones, operaciones y anulaciones de los vendedores"}
              >
                <NewButton backgroundColor={true} onClick={() => porcentajesModal.handleModal()}><FiPercent />Gestionar Porcentajes</NewButton>
              </NewTitlePageSection>
              {!ven ?
              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={2}>
                  <NewInputSelect
                    name={"mes"}
                    labelName={"Mes"}
                    onChange={(e) => setMes(e.target.value)}
                    placeholder={"Mes"}
                    options={meses}
                    value={mes}
                  />
                </Col>
                <Col desktop={2}>
                  <NewInputSelect
                    name={"anio"}
                    labelName={"Año"}
                    onChange={(e) => setAnio(e.target.value)}
                    placeholder={"Año"}
                    options={anios}
                    value={anio}
                  />
                </Col>
                <Col desktop={2}>
                  <NewInputSelect
                    name={"vendedor"}
                    labelName={"Vendedor"}
                    onChange={(e) => setVendedor(e.target.value)}
                    placeholder={"Vendedor"}
                    options={vendedores}
                    value={vendedor}
                  />
                </Col>
                <Col desktop={2} alignBottom={true}>
                  <NewButton backgroundColor={true} disabled={!vendedor} onClick={handleComisiones}>
                    Ver comisiones
                  </NewButton>
                </Col>
              </Grid>
              : null}

              <NewTabs
                  tabs={TABS}
                  tabSelected={tabSelected}
                  handleTab={handleTab}
              />

              {tabSelected === 0 ?
                <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={12}>
                  <Subtitle
                    icon={<FiDollarSign />}
                    title={"Comisiones"}
                    description={`Información sobre las comisiones del vendedor`}
                    disableDivider={true}
                  />
                </Col>
                {comisiones?.length > 0 && Array.isArray(comisiones[0]) ? comisiones.map(vendedor => {
                  return (
                    <Col desktop={12}>
                    <NewTable
                      columns={tableData}
                      data={vendedor}
                      loading={loading}
                      order={handleTableOrder}
                      orderParams={order}
                      spaced={true}
                    />
                  </Col>
                  )
                }) : 
                <Col desktop={12}>
                <NewTable
                  columns={tableData}
                  data={comisiones}
                  loading={loading}
                  order={handleTableOrder}
                  orderParams={order}
                  spaced={true}
                />
              </Col>
                }

              </Grid>
              : null}

              {tabSelected === 1 ?
                <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={12}>
                  <Subtitle
                    icon={<FiFile />}
                    title={"Operaciones"}
                    description={`Lista de operaciones realizadas`}
                    disableDivider={true}
                  />
                </Col>

                <Col desktop={12}>
                  <NewTable
                    columns={operacionesTableData}
                    data={operaciones}
                    loading={loading}
                    order={handleTableOrder}
                    orderParams={order}
                    spaced={true}
                  />
                </Col>
              </Grid>
              : null}

              {tabSelected === 2 ? (
                <Grid colGap={21} rowGap={21} narrow={false}>
                  <Col desktop={12}>
                    <Subtitle
                      icon={<FiFolder />}
                      title={"Anulaciones"}
                      description={`Lista de anulaciones`}
                      disableDivider={true}
                    />
                  </Col>

                  <Col desktop={12}>
                    <NewTable
                      columns={anulacionesTableData}
                      data={anulaciones}
                      loading={loading}
                      order={handleTableOrder}
                      orderParams={order}
                      spaced={true}
                    />
                  </Col>
                </Grid>
              ) : null}
            </Container>
          </NewInner>
        </NewWrapper>
      </Layout>
    </>
  );
};

export default Comisiones;
