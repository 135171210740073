import React from "react";
import Modal from "../../../Modal";
import { ModalEmision, RechazoContainer } from "./styled";
import { Col, Grid } from "../../../Grid";
import { useForm } from "../../../../hooks/useForm";
import ErrorInput from "../../../ErrorInput";
import { limitesPremiosReglas } from "../../../../utils/formRules";
import { useLoading } from "../../../../hooks/useLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import axiosRequest from "../../../../utils/axiosConfig";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import NewInputBasic from "../../../NewInputBasic";
import { useEffect } from "react";
import Divider from "../../../Divider";
import { useState } from "react";
import Success from "../../../Success";
import { FiInfo, FiStar, FiUser } from "react-icons/fi";
import SpinnerLoading from "../../../SpinnerLoading";
import NewInputTextarea from "../../../NewInputTextarea";

const ModalEditarPremio = ({ modal, premio, refreshPremios }) => {
  const { isModalOpen, handleModal } = modal;
  const user = JSON.parse(localStorage.getItem('userInfo'))

  console.log(premio)

  let obj = {}
  const keys = Object.keys(premio).filter(
    (item) =>
      item !== "id_premio" &&
      item !== "estado" &&
      item !== "id_vendedor_premio"
  );
  keys.forEach((key) => {
    if (premio[key] === 1) {
      obj[key] = premio[key];
    }
  });
  console.log(obj)

  const {
    inputValues,
    handleChange,
    formErrors,
    handleValidation,
    completeForm,
  } = useForm(handleSubmit, () => {}, premio);
  const formatter = new Intl.NumberFormat("es-ES", {});
       
  const { loading, setLoading, loadingText } =
    useLoading(false);
  const [success, setSuccess] = useState(false);

  async function handleSubmit() {
    setLoading(true);
    try {
      const update = await axiosRequest.put(`/premios/${premio.id_vendedor_premio}`, inputValues);
      const historial = {
        mensaje: `${user.nombre_operador} editó el premio ${premio.nombre}. Cambios: Nombre del premio - ${inputValues.nombre}. Descripción del premio - ${inputValues.descripcion}`,
        dia: new Date(),
        id_vendedor: null,
        id_premio: premio.id_vendedor_premio,
        id_usuario: user.id_operador,
        id_tipo_modificacion: 1
      }  
      await axiosRequest.post(
        `/premios/historial_modificaciones`,
        historial
      );
      if (update.status === 200) {

        setLoading(false);
        setSuccess(true);
        refreshPremios()
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setSuccess(false);
    handleModal();
  };

  return (
    <div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Premio {premio.nombre}</NewTitle>
            <p>
              Modificá el nombre y descripción del premio
            </p>
          </div>
          <Divider />
          {!success ? (
            <>
              <div className="data-container">
                <div>
                <h4 className="titulo-aclaracion"><FiInfo />Sobre la descripción</h4>
                <div className="aclaracion">
                  <p>Las palabras entre llaves en la descripción ({`{ejemplo}`}) serán reemplazadas por los valores asignados a cada vendedor cuando vean la descripción del premio en su cuenta.</p>
                  <p>En este premio, las palabras que pueden utilizarse para ser reemplazadas por valores son: <strong>{Object.keys(obj).join(", ")}</strong></p>
                </div>
                </div>

                <NewInputBasic
                  type="text"
                  labelName="Nombre"
                  name="nombre"
                  placeholder="Nombre del premio"
                  onChange={handleChange}
                  value={inputValues.nombre}
                />
                {formErrors.nombre && (
                  <ErrorInput>{formErrors.nombre}</ErrorInput>
                )}
                <NewInputTextarea
                  type="text"
                  labelName="Descripción"
                  name="descripcion"
                  placeholder="Descripcion del premio"
                  onChange={handleChange}
                  value={inputValues.descripcion}
                />
                {formErrors.descripcion && (
                  <ErrorInput>{formErrors.descripcion}</ErrorInput>
                )}
              </div>
              <Divider />
              <div className={"actionButtonsCont"}>
                <div className="buttonsCont">
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={handleModal}
                  >
                    Cancelar
                  </NewButton>
                  <NewButton
                    backgroundColor={true}
                    onClick={handleValidation}
                    disabled={loading}
                  >
                    {loadingText ? loadingText : "Actualizar"}
                  </NewButton>
                </div>
              </div>
            </>
          ) : (
            <Success
              title="¡Premio actualizado!"
              description="El premio fue actualizado correctamente"
              handleModal={handleCloseModal}
            />
          )}
        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalEditarPremio;
