// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import FilterSection from "../../../components/FiltersSection";
import TableInfo from "../../../components/TableInfo";
import ModalDescargaCSV from "../../../components/Modals/General/ModalDescargaCSV";
import TitlePageSection from "../../../components/TitlePageSection";
import Button from "../../../components/Button";
import Subtitulo from "../../../components/Subtitulo";
import { Container, Icon } from "./styled";
// Custom Hooks
import useFiltersNew from "../../../hooks/useFiltersNew";
import useProductoCanal from "../../../hooks/useProductoCanal"
import { useTable } from "../../../hooks/useTable";
import useModal from "../../../hooks/useModal";
// Filters
import { filtersProductoCanal } from "../../../utils/filtersPages";
import { filterProductoCanalInitialValues } from "../../../utils/filtersPages";
// Table
import { productoCanalTable } from "../../../utils/tableData";
// Icons
import { MdFileDownload } from "react-icons/md";
import { IoStatsChart } from "react-icons/io5"
import { BsPieChart } from "react-icons/bs"
import SpinnerLoading from "../../../components/SpinnerLoading";
import NewSidebar from "../../../components/NewSidebar";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import NewButton from "../../../components/NewButton";
import NewFiltersSection from "../../../components/NewFiltersSection";
import Divider from "../../../components/Divider";
import NewTable from "../../../components/NewTable";
import CardSinResultados from "../../../components/CardSinResultados";

const EstadisticasProductoCanal = () => {

    const { order, handleTableOrder } = useTable()
    const { isModalOpen, handleModal } = useModal()

    const { data, getEstadisticas, loading, descarga } = useProductoCanal()
    const { inputValues, params, handleSearch, handleChange, clearSearch } = useFiltersNew(filterProductoCanalInitialValues, getEstadisticas)

    const filters = filtersProductoCanal(data)

    const tableData = productoCanalTable(data.mesAnterior)

    return (
        <div>

            <ModalDescargaCSV isModalOpen={isModalOpen} setIsModalOpen={handleModal} descarga={descarga} />
            <Layout>
            <NewWrapper>
                <NewInner>
                    <Container>
                <Grid colGap={21} rowGap={21} narrow={false}>
                    
                    <Col desktop={12}>
                    <NewTitlePageSection title="Estadísticas Producto Canal" description={"Estadísticas vinculadas a los productos y canales de operaciones"}>
                        <NewButton backgroundColor={true} borderColor={false} textColor={false} icon={false} disabled={Object.values(data.total).length === 0} onClick={handleModal}>
                            <span><MdFileDownload></MdFileDownload></span>Descargar estadísticas
                        </NewButton>
                    </NewTitlePageSection>
                    </Col>

                    <Col desktop={12}>
                    <NewFiltersSection section="estadísticas" inputs={filters} values={inputValues} params={params} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} required={true} />
                    </Col>
                </Grid>

                {data.marketing.length === 0 ? 
                    <>
                        {loading ?
                            <SpinnerLoading text={"Cargando estadísticas"} />
                        :
                            <CardSinResultados icon={<BsPieChart/>} title={"No hay filtros aplicados"} description={"Usá los filtros desde, hasta y producto para obtener estadísticas de todos los canales y productos."}/>
                        }
                    </>
                :
                    <>
                        <Divider />
                        <NewTable columns={tableData} data={data.marketing} loading={loading} order={handleTableOrder} orderParams={order} title="Marketing" total={data.subtotalMarketing} />
                        <Divider />
                        <NewTable columns={tableData} data={data.comercial} loading={loading} order={handleTableOrder} orderParams={order} title="Comercial" total={data.subtotalComercial} />
                        <Divider />
                        <NewTable columns={tableData} data={data.otrosCanales} loading={loading} order={handleTableOrder} orderParams={order} title="Otros Canales" total={data.subtotalOtrosCanales} />
                        <Divider />
                        <NewTable columns={tableData} data={[data.total]} loading={loading} order={handleTableOrder} orderParams={order} title="Total" />
                    </>
                }
                </Container>
                </NewInner>
            </NewWrapper> 
            </Layout>       
        </div>
    )
}

export default EstadisticasProductoCanal
