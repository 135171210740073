import React from "react";
import Modal from "../../../Modal";
import { ModalEmision, RechazoContainer } from "./styled";
import Button from "../../../Button";
import Titulo from "../../../Titulo";
import InputFile from "../../../InputFile";
import { Col, Grid } from "../../../Grid";
import { useForm } from "../../../../hooks/useForm";
import ErrorInput from "../../../ErrorInput";
import { limitesPremiosReglas, objetivosReglas, polizaReglas, porcentajeObjetivoOperacionesPremiosReglas } from "../../../../utils/formRules";
import { limitesPremiosForm, objetivosForm, polizaForm } from "../../../../utils/formInitialValues";
import {
  actualizarOperacion,
  actualizarSolicitud,
  postMensajeHistorial,
  postSolicitudPoliza,
} from "../../../../services/Emision";
import InputBasic from "../../../InputBasic";
import dayjs from "dayjs";
import sendFiles from "../../../../utils/uploadFile";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import { useSocket } from "../../../../context/SocketProvider";
import axiosRequest from "../../../../utils/axiosConfig";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import NewInputBasic from "../../../NewInputBasic";
import { useEffect } from "react";
import { meses } from "../../../../constants/data";
import Divider from "../../../Divider";
import { useState } from "react";
import Subtitle from "../../../Subtitle";
import { FiDollarSign } from "react-icons/fi";
import GoalConverter from "../../../GoalConverter";
import NewTabs from "../../../NewTabs";
import { useTabs } from "../../../../hooks/useTabs";
import Success from "../../../Success"

const ModalActualizarLimites = ({ modal, limites, refreshLimites, actualizarCalculoPremios, porcentajeObjetivoOperaciones }) => {
  const { isModalOpen, handleModal } = modal;
  const {
    inputValues,
    handleChange,
    formErrors,
    handleValidation,
    completeForm,
  } = useForm(handleSubmit, limitesPremiosReglas, limites);
  const formatter = new Intl.NumberFormat("es-ES", {});

  const porcentajeForm = useForm(handleSubmitPorcentaje, porcentajeObjetivoOperacionesPremiosReglas, porcentajeObjetivoOperaciones);

  const { loading, setLoading, loadingText, changeLoadingText } =
    useLoading(false);
  const exitoModal = useModal();
  const { tabSelected, handleTab } = useTabs()
  const [success, setSuccess] = useState(false)

  async function handleSubmitPorcentaje() {
    setLoading(true);
    changeLoadingText("Actualizando...");
    console.log(porcentajeForm.inputValues)
    try {
        const update = await axiosRequest.put('/premios/porcentaje_objetivo_operaciones', porcentajeForm.inputValues)
        if(update.status === 200){
            refreshLimites()
            changeLoadingText("¡Porcentaje actualizados!")
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
              changeLoadingText(null)
            }, 1000)
          }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function handleSubmit() {
    setLoading(true);
    changeLoadingText("Actualizando...");
    try {
        const update = await axiosRequest.put('/premios/limites', inputValues)
        if(update.status === 200){
            refreshLimites()
            actualizarCalculoPremios()
            changeLoadingText("¡Límites actualizados!")
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
              changeLoadingText(null)
            }, 1000)
          }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setSuccess(false)
    handleModal()
  }

  return (
    <div>
      <ModalSuccess
        isSuccessPopUpOpen={exitoModal.isModalOpen}
        setIsSuccessPopUpOpen={exitoModal.handleModal}
        popUpMessage={"¡Objetivos cargados!"}
      />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Condiciones</NewTitle>
            <p>
                Actualizá los límites para los topes generales y por objetivos
            </p>
          </div>
          <Divider />
          {!success ?
          <>
            <div className="data-container">
            <NewTabs 
              tabs={[{ name: 'Límites' }, { name: 'Operaciones' }]}
              tabSelected={tabSelected}
              handleTab={handleTab}
            />
            {tabSelected === 0 ? 
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={6}>
                <NewInputBasic
                  type="number"
                  labelName="Límite general"
                  name="limite_general"
                  placeholder="100"
                  onChange={handleChange}
                  value={inputValues.limite_general}
                />
                {formErrors.limite_general && (
                  <ErrorInput>{formErrors.limite_general}</ErrorInput>
                )}
              </Col>
              <Col desktop={6}>
                <NewInputBasic
                  type="number"
                  labelName="Límite objetivos (prima y operaciones)"
                  name="limite_objetivos"
                  placeholder="1000000"
                  onChange={handleChange}
                  value={inputValues.limite_objetivos}
                />
                {formErrors.limite_objetivos && (
                  <ErrorInput>{formErrors.limite_objetivos}</ErrorInput>
                )}
              </Col>
            </Grid>
            : null}
            {tabSelected === 1 ? 
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={6}>
                <NewInputBasic
                  type="number"
                  labelName="Porcentaje Objetivo Operaciones"
                  name="operaciones_porcentaje_objetivo"
                  placeholder="100"
                  onChange={porcentajeForm.handleChange}
                  value={porcentajeForm.inputValues.operaciones_porcentaje_objetivo}
                />
                {porcentajeForm.formErrors.operaciones_porcentaje_objetivo && (
                  <ErrorInput>{porcentajeForm.formErrors.operaciones_porcentaje_objetivo}</ErrorInput>
                )}
              </Col>
            </Grid>
            : null}
          </div>
          <Divider />
          <div className={"actionButtonsCont"}>
            <div className="buttonsCont">
              <NewButton
                backgroundColor={false}
                textColor={true}
                borderColor={true}
                onClick={handleModal}
              >
                Cancelar
              </NewButton>
              <NewButton
                backgroundColor={true}
                onClick={tabSelected === 0 ? handleValidation : porcentajeForm.handleValidation}
                disabled={loading}
              >
                {loadingText ? loadingText : "Actualizar"}
              </NewButton>
            </div>
          </div>
          </>  
          :
          <Success title="¡Valores actualizados!" description="Los valores fueron actualizados correctamente" handleModal={handleCloseModal}/>
          }

        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalActualizarLimites;